<template>
    <div class="table-wrap">
        <div class="table-header">
            <div 
                v-for="(item,index) in tableHeader" 
                :key="index"
                :style="{width:tableW[index]}"
            >{{item}}</div>
        </div>
        <div class="table-row">
            <div class="table-tr"
                v-for="(item,idx) in tableRow"
                :key="idx"
            >
                <div 
                    class="table-td"
                    v-for="(row,index) in rowKey"
                    :key="index"
                    :style="{width:tableW[index],color:operateInfo.rowIndex==index?operateInfo.color:''}"
                    @click="tableDetails(item,index)"
                >
                    {{getRowItem(item,row)}}
                </div>
            </div>
            <div class="more" v-if="tableRow.length == 0">暂无数据</div>
        </div>
        <div class="more" v-if="isShow">暂无更多</div>
    </div>
</template>

<script>
    export default {
        props: {
            tableHeader: {
                type: Array,
                default: () => [ '消费时间', '消费金额', '佣金', '操作' ]
            },
            tableW: {
                type: Array,
                default: () => [ '27%', '27%', '27%', '19%' ]
            },
            tableRow: {
                type: Array,
                default: () => []
            },
            rowKey: {
                type: Array,
                default: () => []
            },
            operateInfo: {
                type: Object,
                default: () => ({rowIndex: 3, color: '#3E6EF1' })
            },
            isShow: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            getRowItem() {
                return (item,{ key, unit }) => {
                    const val = (unit || '') + (item[key] || '-')
                    return key ? val  : unit
                }
            }
        },
        methods: {
            tableDetails(itme, index) {
                if(index == this.operateInfo.rowIndex) {
                    this.$emit('tdDetails', itme)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.table-wrap{
    margin: auto;
    margin-top: r(30);
    width: r(670);
    padding-bottom: r(10);
    .table-header{
        @include font(r(24),#A2AAC1,center);
        @include flex();
        height: r(72);
        background: #F9FAFE;
    }
    .table-row{
        .table-tr{
            @include font(r(24),#697085,center);
            @include flex();
            height: r(72);
            border-bottom: r(2) solid #F2F5FF;
            .table-td{
                @include overflow(1);
                padding: 0 r(10);
            }
        }
    }
    .more{
        @include font(r(24),#A2AAC1,center);
        margin-top: r(10);
    }
}
</style>