<template>
    <div class="details-wrap">
        <div class="details-header">
            <div>个人信息</div>
            <van-button type="info" @click="applyDeposit">提现申请</van-button>
        </div>
        <ul class="user-info">
            <li>
                <span>ID</span>
                <span>{{recordInfo.id}}</span>
            </li>
            <li>
                <span>姓名</span>
                <span>{{recordInfo.nickname}}</span>
            </li>
             <li>
                <span>分享获客</span>
                <span>{{recordInfo.invite_apply_submit_count}}</span>
            </li>
            <li>
                <span>成交获客</span>
                <span>{{recordInfo.deal_count}}</span>
            </li>
            <li>
                <span>下级充值金额</span>
                <span>￥{{recordInfo.son_recharge_total}}</span>
            </li>
            <li>
                <span>下级消费金额</span>
                <span>￥{{recordInfo.son_spend_total}}</span>
            </li>
            <li>
                <span>待返佣金</span>
                <span>￥{{recordInfo.wait_money}}</span>
            </li>
            <li>
                <span>可提现佣金(下级消费后返佣金)</span>
                <span>￥{{recordInfo.withdraw_money}}</span>
            </li>
            <li>
                <span>已提现佣金</span>
                <span>￥{{recordInfo.already_withdraw}}</span>
            </li>
        </ul>
        <div class="details-content">
            <div class="but-list">
                <div 
                    :class="['but-item',activeIdx==index?'active':'']" 
                    v-for="(item,index) in butList" 
                    :key="index"
                    @click="changeRecord(index)"
                >
                    {{item}}
                </div>
            </div>
            <userTable 
                v-if="activeIdx == 0"
                :tableRow="bountyList"
                :rowKey="bountTableKey"
                @tdDetails="tdDetails($event,0)"
                :isShow="isShowBounty"
            />
            <userTable 
                v-else-if="activeIdx == 1"
                :tableRow="tableRow"
                :tableHeader="['提现时间','提现金额','状态']"
                :tableW="['45%', '30%', '25%']"
                :rowKey="recordTableKey"
                :isShow="isShowMore"
            />
            <userTable 
                v-else
                :tableRow="refundTableData"
                :tableHeader="['退款金额','退款时间','退款(部分)后佣金', '操作']"
                :tableW="['25%', '25%', '30%', '20%']"
                :rowKey="refundTableKey"
                :isShow="isShowRefund"
                @tdDetails="tdDetails($event,1)"
            />
            <van-dialog v-model="isShow" :title="detailsTitle" close-on-click-overlay>
               <ul>
                   <li v-for="(item, idx) in detailsInfoKey" :key="idx">
                       <span>{{item.label}}</span>
                       <span>{{ (item.unit || '') + (detailsInfo[item.key] || '-')}}</span>
                   </li>
               </ul>
            </van-dialog>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import userTable from '@/components/mobile/mobileUserTable'
    export default {
        data() {
            return {
                butList: [ '佣金记录', '提现记录', '下级退款记录' ],
                activeIdx: 0,
                //佣金记录
                bountyList: [],
                bountTableKey: [
                    {
                        key: 'create_time_str'
                    },
                    {
                        key: 'money',
                        unit: '￥'
                    },
                    {
                        key: 'commission',
                        unit: '￥'
                    },
                    {
                        unit: '详情'
                    }
                ],
                //提现记录
                tableRow: [],
                recordTableKey: [
                    {
                        key: 'create_time_str'
                    },
                    {
                        key: 'money',
                        unit: '￥'
                    },
                    {
                        key: 'statusText'
                    }
                ],
                //下级退款记录
                 refundTableKey: [
                    {
                        key: 'money',
                        unit: '￥'
                    },
                    {
                        key: 'create_time_str',
                        name: '退款时间'
                    },
                    {
                        key: 'commission',
                        unit: '￥'
                    },
                    {
                        unit: '详情'
                    }
                ],
                refundTableData: [],
                detailsInfo: {},
                isShow: false,
                bountyPages: {
                    page: 1,
                    pagesize: 15
                },
                depositPages: {
                    page: 1,
                    pagesize: 15
                },
                refundPages: {
                    page: 1,
                    pagesize: 15
                },
                //详情
                detailsInfoKey: [],
                detailsTitle: '佣金记录',
                isLoading: false,
                isShowMore: false,
                isShowBounty: false,
                isShowRefund: false
            }
        },
        components: {
            userTable
        },
        computed: {
            ...mapState([ 'recordInfo' ])
        },
        created(){
            this.$store.dispatch('getMyRecord')
            this.getBountyList()
        },
        mounted(){
            window.addEventListener('scroll', this.scrollBottom)
        },
        methods: {
            changeRecord(idx) {
                if(this.activeIdx == idx) return
                this.activeIdx = idx
                this.isLoading = false
                if(idx == 0) {
                    this.bountyPages.page = 1
                    this.bountyList = []
                    this.getBountyList()
                } else if(idx == 1) {
                    this.depositPages.page = 1
                    this.tableRow = []
                    this.getWithdrawalList()
                } else {
                    this.refundPages.page = 1
                    this.refundTableData = []
                    this.getRefundList()
                }
            },
            tdDetails(e, idx) {
                if(idx == 0) {
                    this.detailsTitle = '佣金记录'
                    this.detailsInfoKey = [
                        {
                            label: '消费时间：',
                            key: 'create_time_str'
                        },
                        {
                            label: '客户：',
                            key: 'nickname'
                        },
                        {
                            label: '消费金额：',
                            key: 'money',
                            unit: '￥'
                        },
                        {
                            label: '佣金：',
                            key: 'commission',
                            unit: '￥'
                        },
                        {
                            label: '佣金状态：',
                            key: 'status_name'
                        }
                    ]
                } else {
                    this.detailsTitle = '退款详情'
                    this.detailsInfoKey = [
                        {
                            label: '客户ID：',
                            key: 'user_id'
                        },
                        {
                            label: '客户姓名：',
                            key: 'nickname'
                        },
                        {
                            label: '退款金额：',
                            key: 'money',
                            unit: '￥'
                        },
                        {
                            label: '退款时间：',
                            key: 'create_time_str'
                        },
                        {
                            label: '退款(部分)后佣金：',
                            key: 'commission',
                            unit: '￥'
                        }
                    ]
                }
                this.detailsInfo = e
                this.isShow = true
            },
            applyDeposit() {
                const { withdraw_money: money} = this.recordInfo
                if(!money || money == '0.00') {
                    this.$toast('暂无可提佣金')
                    return
                }
                sessionStorage.setItem('money', money)
                this.$router.push('mobileDeposit')
            },
            // 佣金记录
            getBountyList() {
                this.isLoading = true
                this.$getHttp(this.$api.getCommissionRecord, this.bountyPages).then(res => {
                    const { list, last_page } = res.data
                    if(list && list.length) {
                        this.bountyList = [ ...this.bountyList, ...list ]
                        this.isLoading = false
                    }
                    if(this.bountyPages.page == last_page ) {
                        this.isLoading = true
                        this.isShowBounty = true
                    }
                })
            },
            // 提现佣金
            getWithdrawalList() {
                this.isLoading = true
                this.$getHttp(this.$api.getWithdrawList, this.depositPages).then(res => {
                    const { list, last_page } = res.data
                    if(list && list.length) {
                        const recordList = list.map(item => {
                            item.statusText = item.status == 0 ? item.settle_name : item.status_name
                            return item
                        })
                        this.tableRow = [ ...this.tableRow, ...recordList ]
                        this.isLoading = false
                    }
                    if(this.depositPages.page == last_page ) {
                        this.isLoading = true
                        this.isShowMore = true
                    }
                })
            },
            // 下级佣金退款记录
            getRefundList(){
                this.$getHttp(this.$api.getRefundList, this.refundPages).then(res => {
                    const { list, last_page } = res.data
                    if(list && list.length) {
                        this.refundTableData = [ ...this.refundTableData, ...list ]
                        this.isLoading = false
                    }
                    if(this.refundPages.page == last_page ) {
                        this.isLoading = true
                        this.isShowRefund = true
                    }
                })
            },
            // 触底
            scrollBottom (){
                let t=document.documentElement.scrollTop;
                let h=document.documentElement.clientHeight;
                let v=document.body.scrollHeight;
                if(t+h>=v && !this.isLoading){ 
                    if(this.activeIdx == 0) {
                        this.bountyPages.page++
                        this.getBountyList()
                    } else if(this.activeIdx == 1) {
                        this.depositPages.page++
                        this.getWithdrawalList()
                    } else {
                        this.refundPages.page++
                        this.getRefundList()
                    }
                }
            }
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.scrollBottom)
        }
    }
</script>

<style lang="scss" scoped>
.details-wrap{
    background: #F9FAFE;
    overflow: hidden;
    .details-header{
        margin: r(16) r(40);
        @include flex(flex-end,space-between);
        @include font(r(24),#697085);
        ::v-deep .van-button{
            line-height: r(48);
            @include font(r(24),#fff,center);
            @include wh(r(128),r(48));
            padding: 0;
            &.van-button--info{
                background: $main_color;
                border-color: $main_color;
            }
        }
    }
    .user-info{
        padding: r(40);
        background: #fff;
        margin-bottom: r(20);
        li{
            @include flex();
            @include font(r(28),#697085);
            margin-bottom: r(28);
            &:last-child{
                margin-bottom: 0;
            }
            span{
                &:last-child{
                    flex: 1;
                    text-align: right;
                    color: $main_font_color;
                }
            }
        }
    }
    .details-content{
        background: #fff;
        .but-list{
            @include flex(center,flex-start);
            @include font(r(28),#A2AAC1);
            height: r(96);
            border-bottom: r(2) solid #F2F5FF;
            .but-item{
                margin-left: r(40);
                position: relative;
                &.active{
                    color: $main_color;
                    &::after{
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: r(-30);
                        width: 100%;
                        height: r(8);
                        background: $main_color;
                    }
                }
            }
        }
        ::v-deep .van-dialog{
            width: r(680);
            .van-dialog__header{
                color: $main_color;
            }
            ul{
                @include flex(center,flex-start);
                flex-wrap: wrap;
                padding: 0 r(20);
                margin: r(40) 0;
                li{
                    width: 62%;
                    margin-bottom: r(24);
                    @include font(r(28),$main_font_color);
                    @include flex(flex-start,flex-start);
                    &:nth-child(2n){
                         width: 35%;
                    }
                    span{
                        &:first-child{
                            min-width: r(90);
                            max-width: r(150)
                        }
                    }
                }
            }
            .van-dialog__footer{
                @include wh(r(240),r(90));
                line-height: r(90);
                background: $main_color;
                margin: auto;
                margin-bottom: r(40);
                border-radius:r(24);
                &::after{
                    display: none;
                }
                .van-button--large{
                    height: r(90);
                    background: $main_color;
                     @include font(r(32),#fff,center);
                }
            }
        }
    }
    
}
</style>